import { useFetchPublicApi } from '~/composables/fetch/fetchComposables'
import {contentServiceURL} from './apiDomain'
import {createHttp} from './http'

// let client = createHttp(contentServiceURL)

export default {
    getFilters(params: any) {
        return useFetchPublicApi.get(`${contentServiceURL}/trips/filter-options`, params, {
            baseURL: process.server ? useRuntimeConfig().localProductContentUrl : useAppConfig().baseApiUrl
        })
    },
    getDestinationAutocomplete(query) {
        const client = createHttp(contentServiceURL)
        return client.get(`/destinations/autocomplete?q=${query.q}&level=${query.level}`)
    },
    popular(params:any) {
        return useFetchPublicApi.get(`${contentServiceURL}/destinations/popular`, params, {
            baseURL: process.server ? useRuntimeConfig().localProductContentUrl : useAppConfig().baseApiUrl
        })
    }
}
