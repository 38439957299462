import { defineStore } from 'pinia'
import getFiltersApi from '~/composables/api/filtersApi'
import type { Pagination } from '~/models/common.model'
import type { Destination, FiltersOptionGallery } from '~/models/enquiryOption.model'
import type { Gallery, QuickLink } from '~/models/gallery.model'
import type { TravelGuide } from '~/models/travelGuide'
import type { TripPlan } from '~/models/trip.model'

export const useGalleryStore = defineStore('storeGallery', {
  state: () => ({
    filtersData: null as FiltersOptionGallery | null,
    destinationsData: null as TravelGuide | null,
    galleryInfor: null as Gallery | null,
    popularList: [] as Array<Destination>,
    filterSelected: null as { designerSelected: string } | null,
    tripData: {} as Pagination<TripPlan>,
    clearFilter: false,
    quickLinksData: null as { destinaions: QuickLink; activities: QuickLink; duration: QuickLink } | null,
  }),
  actions: {
    async popularDestination(request: { limit: number; level: number; country_code: string; is_free_cancellation: boolean }) {
      const response = (await getFiltersApi.popular(request)).data.value
      this.setPopularDestination((response?.data as Array<Destination>) || [])
      return response || []
    },
    // Mutations
    setPopularDestination(response: Array<Destination>) {
      this.popularList = response
    },
    setFiltersList(response: FiltersOptionGallery) {
      this.filtersData = response
    },
    setFiltersSelected(response: { designerSelected: string }) {
      this.filterSelected = response
    },
    setDestinations(response: TravelGuide) {
      this.destinationsData = response
    },
    setGalleryInfor(response: Gallery) {
      this.galleryInfor = response
    },
    setTripData(response: Pagination<TripPlan>) {
      this.tripData = response
    },
    setClearFilter(response: boolean) {
      this.clearFilter = response
    },
    setQuickLinks(data: Array<QuickLink>) {
      let list = {
          destinaions: data ? data[0] : [] as any,
          activities: data ? data[1] : [] as any,
          duration: data ? data[2] : [] as any,
      }
      this.quickLinksData = list
    },
  },
})
